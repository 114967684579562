/* eslint-disable class-methods-use-this */
import EventClass from "common/utils/ui/event-class";
import { track } from "jiffy-analytics";

class DTFCategoryFilter extends EventClass {
  get #$sections() {
    return document.querySelectorAll(".js-dtf-category");
  }

  constructor() {
    super({
      eventSelector: ".js-dtf-category-header"
    });
  }

  event(e, $header) {
    const { headerSection } = e.target.dataset;
    if ($header && headerSection) {
      track("dtf_category_selected", { category: headerSection });
      const isAllProducts = headerSection === "All Products";
      const $headingItems = Array.from($header.children);
      // Match the index of the heading item with the index of the section
      const $sections = [null, ...Array.from(this.#$sections)];

      $headingItems.forEach(($heading, index) => {
        $heading.classList.toggle("active", $heading.dataset.headerSection === headerSection);

        if (index > 0) {
          const $sectionHeader = $sections[index]?.querySelector(".js-products-header");

          $sectionHeader.classList.toggle("hidden", !isAllProducts);
          $sections[index].classList.toggle(
            "hidden",
            !(isAllProducts || $sections[index].dataset.section === headerSection)
          );
        }
      });
    }
  }
}

export default () => new DTFCategoryFilter();
